import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: './home/home.module#HomePageModule'
  },
  { path: 'register', loadChildren: './register-account/register-account.module#RegisterAccountPageModule' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardPageModule' },
  { path: 'new-ticket', children: [
    {
      path: '', loadChildren: './new-ticket/new-ticket.module#NewTicketPageModule'
    },{
      path: ':categoryId', loadChildren: './new-ticket/new-ticket.module#NewTicketPageModule'
    }
  ]},
  { path: 'new-ticket-confirmation', loadChildren: './new-ticket/confirmation/confirmation.module#ConfirmationPageModule' },
  { path: 'chats', loadChildren: './chats/chats.module#ChatsPageModule' },
  { path: 'chat-log/:id', loadChildren: './chat-log/chat-log.module#ChatLogPageModule' },
  { path: 'settings', loadChildren: './settings/settings.module#SettingsPageModule' },
  { path: 'confirm-register', loadChildren: './register-account/confirmation/confirm-register.module#ConfirmRegisterPageModule' },
  { path: 'set-password', loadChildren: './set-password/set-password.module#SetPasswordPageModule' },
  { path: 'forgot-password', loadChildren: './forgot-password/forgot-password.module#ForgotPasswordPageModule' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}