import { Component } from '@angular/core';

import { Platform, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';

import { RemoteService } from './remote.service';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html'
})
export class AppComponent {

	menuVisible: boolean = false

	constructor(
		private platform: Platform,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		private remoteService: RemoteService,
		private router: Router,
		private menuController: MenuController
	) {
		this.initializeApp();
	}

	initializeApp() {
		this.platform.ready().then(() => {
			if (this.platform.is('cordova')) {
				this.statusBar.styleDefault();
				this.splashScreen.hide();
			}

			this.remoteService.userMessage.subscribe(user => {
				this.refreshMenuVisibility(user && !user.isAnonymous)
			})
		})
	}

	signOut() {
		this.remoteService.signOut().then(() => {
			this.router.navigateByUrl('')
			this.refreshMenuVisibility(false)
		})
	}

	refreshMenuVisibility(enable: boolean): void {
		this.menuController.enable(enable)
		this.menuVisible = enable
	}
}
