import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { auth } from 'firebase/app';
import { firestore } from 'firebase/app';

export class Project {
	id: string
	title: string

	constructor(project) {
		this.id = project['id']
		this.title = project['title']
	}
}

export class Category {
	id: string
	title: string

	constructor(category: Object = {}) {
		this.id = category['id']
		this.title = category['title']
	}
}

export class Ticket {
	id: string
	categoryId: string
	categoryTitle: string = ''
	subject: string
	created: string
	updated: string
	userId: string
	status: string
	opened: boolean
	messages: Array<TicketMessage> = []

	constructor(ticket: Object = {}) {
		this.id = ticket['id']
		this.categoryId = ticket['categoryId']
		this.subject = ticket['subject']
		this.created = ticket['created'].toDate().getTime()
		this.updated = ticket['updated'].toDate().getTime()
		this.status = ticket['status']
		this.userId = ticket['userId']
		this.opened = ticket['opened']
	}

	updateCategoryTitle(categoryTitle: string): void {
		this.categoryTitle = categoryTitle
	}
}

export class TicketMessage {
	id: string
	message: string
	datetime: string
	userId: string
	isUser: boolean

	constructor(ticketMessage: Object = {}) {
		this.id = ticketMessage['id']
		this.message = ticketMessage['message']
		this.datetime = ticketMessage['datetime'].toDate().getTime()
		this.userId = ticketMessage['userId']
		this.isUser = ticketMessage['isUser']
	}
}

@Injectable({
	providedIn: 'root'
})
export class RemoteService {

	user: firebase.User
	userInFirestore: firestore.DocumentData
	tickets: Array<Ticket>
	categories: Array<Category> = []
	project: Project
	firestoreProject: AngularFirestoreDocument

	private projectSource;
	projectMessage;
	private userSource;
	userMessage;
	private userInFirestoreSource;
	userInFirestoreMessage;
	private ticketsSource;
	ticketsMessage;
	private categoriesSource;
	categoriesMessage;

	constructor(
		private afAuth: AngularFireAuth,
		private afFirestore: AngularFirestore
	) {
		this.firestoreProject = this.afFirestore.collection('projects').doc(window['projectId'])
		this.firestoreProject.get().subscribe((documentSnapshot: firestore.DocumentSnapshot) => {
			if (documentSnapshot.exists) {
				let project = documentSnapshot.data()
				project['id'] = documentSnapshot.id

				this.setProject(new Project(project))
			}
		})

		this.projectSource = new BehaviorSubject<Project>(null);
		this.projectMessage = this.projectSource.asObservable();
		this.projectMessage.subscribe(project => this.project = project);

		this.userSource = new BehaviorSubject<boolean>(false);
		this.userMessage = this.userSource.asObservable();
		this.userMessage.subscribe(user => this.user = user);

		this.userInFirestoreSource = new BehaviorSubject<boolean>(false);
		this.userInFirestoreMessage = this.userInFirestoreSource.asObservable();
		this.userInFirestoreMessage.subscribe(userInFirestore => this.userInFirestore = userInFirestore);

		this.ticketsSource = new BehaviorSubject<Array<Ticket>>(this.tickets)
		this.ticketsMessage = this.ticketsSource.asObservable();
		this.ticketsMessage.subscribe(tickets => {
			this.tickets = tickets

			this.updateTicketCategoryTitle()
		})

		this.categoriesSource = new BehaviorSubject<boolean>(false);
		this.categoriesMessage = this.categoriesSource.asObservable();
		this.categoriesMessage.subscribe(categories => {
			this.categories = categories

			this.updateTicketCategoryTitle()
		})

	    this.afAuth.auth.onAuthStateChanged((user: firebase.User) => {
			if (user) {
				this.setUser(user)

				if (user.email) {
					this.getUserFromFirestore(user.email).then((userInFirestore: firestore.DocumentData) => {
						this.setUserInFirestore(userInFirestore)

						this.loadTickets()
					}).catch(err => {
					    console.log(err)
					})
				}

				this.loadCategories()
			} else {
				this.setUser(null)
				this.setUserInFirestore(null)
			}
	    })
	}

	setProject(project: Project) {
		this.projectSource.next(project)
	}

	setUser(user: firebase.User) {
		this.userSource.next(user)
	}

	setUserInFirestore(userInFirestore: firestore.DocumentData) {
		this.userInFirestoreSource.next(userInFirestore)
	}

	setTickets(tickets: Array<Ticket>) {
		this.ticketsSource.next(tickets)
	}


	setCategories(categories: Array<Category>) {
		this.categoriesSource.next(categories)
	}

	register(params: Object): Promise<void> {
		return new Promise((resolve,reject) => {
			this.getUserFromFirestore(params['email']).then((userInFirestore: firestore.DocumentData) => {
				this.editUserToFirestore(userInFirestore.id, params).then(() => {
					this.sendAdminApproveEmail(userInFirestore.id).then(() => {
						this.setUserInFirestore(userInFirestore)

						resolve()
					}).catch(err => {
						reject(err)
					})
				}).catch(err => {
					reject(err)
				})
			}).catch(err => {
				reject(err)
			})
		})
	}

	createAuthAccount(email: string, password: string): Promise<void> {
		return new Promise((resolve,reject) => {
			this.afAuth.auth.createUserWithEmailAndPassword(email, password).then(userCredential => {
				this.getUserFromFirestore(email).then(user => {
					this.updateProfile({displayName: user['firstname']+' '+user['lastname'], photoURL: null}).then(() => {
						user['authUid'] = userCredential.user.uid

						this.updateUserToFirestore(this.userInFirestore.id, user).then(() => {
							resolve()
						}).catch(err => {
							reject(err)
						})
					}).catch(err => {
						reject(err)
					})
				}).catch(err => {
					reject(err)
				})
			}).catch(err => {
				reject(err)
			})
		})
	}

	addUserToFirestore(data: Object): Promise<string> {
		return new Promise((resolve,reject) => {
			data['created'] = new Date()
			data['modified'] = data['created']

			this.firestoreProject.collection('users', ref => ref.where('email', '==', data['email'])).get().subscribe(doc => {
				if (doc.empty) {
					this.firestoreProject.collection('users').add(data).then((docRef) => {
				    	resolve(docRef.id)
				    }).catch(err => {
				    	reject(err)
				    })
				} else {
					reject({
						message: "User already exist"
					})
				}
			})
		})
	}

	editUserToFirestore(userId: string, data: Object): Promise<string> {
		return new Promise((resolve,reject) => {
			data['modified'] = new Date()

			this.firestoreProject.collection('users').doc(userId).get().subscribe(doc => {
				if (doc.exists) {
					this.updateUserToFirestore(doc.id, data).then(() => {
						resolve()
					}).catch(err => {
						reject(err)
					})
				} else {
					reject()
				}
			})
		})
	}

	updateUserToFirestore(docId: string, data: Object): Promise<void> {
		return new Promise((resolve,reject) => {
			data['modified'] = new Date();

		    this.firestoreProject.collection('users').doc(docId).update(data).then(() => {
				this.setUserInFirestore(data)

				resolve()
		    }).catch(err => {
				reject(err)
		    })
		})
	}

	refreshUserInFirestore(userId: string): Promise<void> {
		return new Promise((resolve,reject) => {
			this.firestoreProject.collection('users').doc(userId).get().subscribe(doc => {
				if (doc.exists) {
					this.setUserInFirestore(doc.data())

					resolve()
				} else {
					reject()
				}
			})
		})
	}

	getUserFromFirestore(email: string): Promise<firestore.DocumentData> {
		return new Promise((resolve,reject) => {
			let docRef = this.firestoreProject.collection('users', ref => ref.where('email', '==', email)).get().subscribe(querySnapshot => {
				if (querySnapshot.empty) {
			    	reject({
			    		message: "User does not exist"
			    	})
				} else {
					querySnapshot.forEach(doc => {
						let user = doc.data()
						user.id = doc.id

			    		resolve(user)
					})
				}
			})
		})
	}

	signIn(email:string, password: string): Promise<void> {
		return new Promise((resolve,reject) => {
			this.getUserFromFirestore(email).then((userInFirestore: firestore.DocumentData) => {
				this.afAuth.auth.signInWithEmailAndPassword(email, password).then((userCredential) => {
					this.setUserInFirestore(userInFirestore)

					resolve()
				}).catch(err => {
					reject(err)
				})
			}).catch(err => {
			    reject(err)
			})
		})
	}

	signInAnonymously(): Promise<auth.UserCredential> {
		return this.afAuth.auth.signInAnonymously()
	}

	signOut(): Promise<void> {
		return this.afAuth.auth.signOut()
	}

	updateProfile(params: {displayName: string, photoURL: string}): Promise<void> {
		return new Promise((resolve,reject) => {
			setTimeout(() => {
				this.user.updateProfile(params).then(() => {
					resolve()
				}).catch(err => {
					reject(err)
				})
			}, 500)
		})
	}

	updateEmailAndProfile(data) {
		return new Promise((resolve,reject) => {
			this.user.updateEmail(data['email']).then(() => {
				this.user.updateProfile({displayName: data['firstname']+' '+data['lastname'], photoURL: null}).then(() => {
					let updatedUserInFirestore = Object.assign({}, this.userInFirestore)

					for (let field in data) {
						updatedUserInFirestore[field] = data[field]
					}

					this.updateUserToFirestore(this.userInFirestore.id, updatedUserInFirestore).then(() => {
						resolve()
					}).catch(err => {
						reject(err)
					})
				}).catch(err => {
					reject(err)
				})
			}).catch(err => {
				reject(err)
			})
		})
	}

	getUser(): Object {
		return this.user
	}

	isSignedIn(): boolean {
		return (this.user != null)
	}

	newTicket(data: {categoryId: string, subject: string, message: string}): Promise<void> {
		let datetime = new Date()

		return new Promise((resolve,reject) => {
		    this.firestoreProject.collection('tickets').add({
		    	categoryId: data.categoryId,
		    	subject: data.subject,
		    	created: datetime,
		    	updated: datetime,
		    	userId: this.userInFirestore.id,
		    	status: 'active',
		    	opened: true
		    }).then(docRef => {
		    	docRef.collection('messages').add({
			    	message: data.message,
			    	userId: this.userInFirestore.id,
			    	datetime: datetime
			    }).then(doc => {
			    	resolve()
			    }).catch(err => {
			    	reject(err)
			    })
		    }).catch(err => {
		    	reject(err)
		    })
		})
	}

	addMessageToTicket(ticketId: string, message: string): Promise<void> {
		let datetime = new Date()

		return new Promise((resolve,reject) => {
		    this.firestoreProject.collection('tickets').doc(ticketId).collection('messages').add({
		    	message: message,
		    	userId: this.userInFirestore.id,
		    	datetime: datetime
		    }).then(docRef => {
		    	docRef.get().then(doc => {
					let data = doc.data()
					data['id'] = doc.id

					data['isUser'] = (data['userId'] == this.userInFirestore.id)

					let ticketIndex = null

					for (let i in this.tickets) {
						if (this.tickets[i].id == ticketId) {
							ticketIndex = i
						}
					}

					if (ticketIndex) {	
				    	resolve()
					} else {
						reject('Ticket not found')
					}
			    }).catch(err => {
			    	reject(err)
			    })
		    }).catch(err => {
		    	reject(err)
		    })
		})
	}

	getTicket(ticketId: string): Promise<Ticket> {
		return new Promise((resolve,reject) => {
			let ticketIndex = null

			for (let i in this.tickets) {
				if (this.tickets[i].id == ticketId) {
					ticketIndex = i
				}
			}

			if (ticketIndex) {
				this.getTicketMessage(ticketId).then(messages => {
					resolve(this.tickets[ticketIndex])
				}).catch(err => {
					resolve(this.tickets[ticketIndex])
				})
			} else {
				reject("Ticket not found")
			}
		})
	}

	getTicketMessage(ticketId: string): Promise<Array<TicketMessage>> {
		return new Promise((resolve,reject) => {
			this.firestoreProject.collection('tickets').doc(ticketId).collection('messages', ref => ref.orderBy('datetime')).snapshotChanges().subscribe(documentChanges => {
				let data = []
				documentChanges.forEach(documentChange => {
					let documentData = documentChange.payload.doc.data()
					documentData['id'] = documentChange.payload.doc.id

					documentData['isUser'] = (documentData['userId'] == this.userInFirestore.id)

					data.push(new TicketMessage(documentData))
				})

				this.updateTicketMessagesInVariable(ticketId, data)

				resolve(data)
			})
		})
	}

	updateTicketMessagesInVariable(ticketId: string, ticketMessages: Array<TicketMessage>) {
		for (let i = 0 ; i < this.tickets.length ; i++) {
			if (this.tickets[i].id == ticketId) {
				this.tickets[i].messages = ticketMessages

				break
			}
		}

		this.setTickets(this.tickets)
	}

	checkUserWithEmail(email: string): Promise<Object> {
		return new Promise((resolve,reject) => {
			this.afAuth.auth.fetchSignInMethodsForEmail(email).then((methods: Array<string>) => {
				if (methods.length) {
					// Methods available, user exists
					resolve(true)
				} else {
					// No methods available, user does not exist
					this.getUserFromFirestore(email).then((userInFirestore: firestore.DocumentData) => {
						resolve(userInFirestore)
					}).catch(err => {
						// User not created in database and no auth account
						reject(false)
					})
				}
			}).catch(err => {
				reject(err)
			})
		})
	}

	loadCategories(): Promise<void> {
		return new Promise((resolve,reject) => {
		    this.afFirestore.collection('categories').snapshotChanges().subscribe(documentChanges => {
				let data = []
				documentChanges.forEach(documentChange => {
					let documentData = documentChange.payload.doc.data()
					documentData['id'] = documentChange.payload.doc.id

					data.push(new Category(documentData))
				})

				data.sort((a, b) => {
					return a.title.localeCompare(b.title)
				})

				this.setCategories(data)

				resolve()
			})
		})
	}

	loadTickets(): Promise<void> {
		return new Promise((resolve,reject) => {
		    this.firestoreProject.collection('tickets', ref => ref.where('userId', '==', this.userInFirestore.id)).snapshotChanges().subscribe(documentChanges => {
				let data = []
				documentChanges.forEach(documentChange => {
					let documentData = documentChange.payload.doc.data()
					documentData['id'] = documentChange.payload.doc.id

					data.push(new Ticket(documentData))
				})

				this.setTickets(data)

				resolve()
			})
		})
	}

	updateTicketCategoryTitle(): void {
		let categoriesTitle = {}

		for (let i in this.categories) {
			categoriesTitle[this.categories[i].id] = this.categories[i].title
		}

		for (let i in this.tickets) {
			if (categoriesTitle[this.tickets[i].categoryId]) {
				this.tickets[i].updateCategoryTitle(categoriesTitle[this.tickets[i].categoryId])
			}
		}
	}

	sendAdminApproveEmail(userId: string): Promise<void> {
		return new Promise((resolve,reject) => {
			this.jsonpRequest('https://econciergerie.preview.saentys.com/email.php?project='+ window['projectId'] + '&user_id=' + userId + '&type=admin_approve').then((data) => {
				if (data['success']) {
					resolve()
				} else {
					reject("Request failed")
				}
			}).catch(err => {
				reject(err)
			})
		})
	}

	jsonpRequest(url) {
		return new Promise((resolve,reject) => {
			let callbackName = 'jsonp_callback_' + Math.round(100000 * Math.random());
			window[callbackName] = function(data) {
			    delete window[callbackName];
			    document.body.removeChild(script);
			    resolve(data);
			};

			let script = document.createElement('script');
			script.src = url + (url.indexOf('?') >= 0 ? '&' : '?') + 'callback=' + callbackName;
			document.body.appendChild(script);
		});
	}

	resetPassword(email: string) : Promise<void> {
		return this.afAuth.auth.sendPasswordResetEmail(email)
	}

}